.my_css {
    display: flex;
    /* min-height: 100vh; */
    margin-top: 40px;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;
}

.bg {
    background-color: "#efefef";
    padding: 1rem;
}

.my_btn {
    text-align: center;
    align-items: center;
    padding-top: 1rem;
}
